import Swiper, { Controller, Navigation, Thumbs } from 'swiper';
document.addEventListener('DOMContentLoaded', function() {
    initSwiper()
})

function initSwiper(){
    Swiper.use([Controller, Navigation, Thumbs]);
    const swiper = new Swiper(".swiper_main_accuroof", {
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        slidesPerView: 1,
    });

    const swiper2 = new Swiper(".swiper_nav_accuroof", {
        slidesPerView: 2,
        touchRatio: 0.2,
        watchSlidesProgress: true,
    });

    swiper.controller.control = swiper2;
    swiper2.controller.control = swiper;
    swiper2.on('tap', function (){
                swiper.slideTo(swiper2.clickedIndex);
            });
}

