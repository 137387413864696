function setEqualHeightAccuroof(className) {
    document.addEventListener('DOMContentLoaded', () => {
        const container = document.querySelectorAll(`.${className}`);
        const maxHeight = Array.from(container).reduce((max, item) => {
            return Math.max(max, item.offsetHeight);
        }, 0);
        container.forEach(item => item.style.minHeight = `${maxHeight}px`);
    });
}

setEqualHeightAccuroof('t3-column-content__title');