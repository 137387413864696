import axios from "axios";

let current_page = document.querySelector('#accuroof-posts-wrapper')?.dataset.page;
let max_pages = document.querySelector('#accuroof-posts-wrapper')?.dataset.max;
let per_page = document.querySelector('#accuroof-posts-wrapper')?.dataset.perPage;
let postType = document.querySelector('#accuroof-posts-wrapper')?.dataset.postType;
const currentUrl = new URL(window.location.href);
const postsWrapper = document.getElementById('accuroof-posts-wrapper');
const postSearch = document.getElementById('accuroof-post-search');
const applyFilters = document.querySelector('.js-accuroof-apply-filters');
const postSelect = document.getElementById('accuroof-post-select');
const clearFilters = document.getElementById('accuroof-post-results__clear');
const filters = document.querySelectorAll('input[name="filters[]"]');
const postFilters = document.querySelectorAll('input[name="postFilters[]"]');

const ajaxFilterPosts = () => {
    document.addEventListener('DOMContentLoaded', function() {
        if (document.querySelector('#accuroof-posts-wrapper')) {
            filterPostsBySelectedFilter();
            ajaxPaginate()
        }

        if(postSearch){
            postSearch.addEventListener('input', filterPostsBySearch);
        }

        if(applyFilters){
            applyFilters.addEventListener('click', filterPostsBySearch);
        }

        clearFilters?.addEventListener('click', (event) => {
            event.preventDefault();
            filters?.forEach(filter => {
                filter.checked = false;
            });
            postFilters?.forEach(filter => {
                filter.checked = false;
            });

            executeQuery(postSearch.value);
        });
    })
}

function ajaxPaginate(){
    const pageNumbers = document.querySelectorAll('.page-numbers');
    pageNumbers.forEach(pageNumber => {
        pageNumber.addEventListener('click', (event) => {
            event.preventDefault();
            const $currentPageEl = Array.from(pageNumbers).find(item => {
                return item.classList.contains('current');
            });
            const currentPage = $currentPageEl.innerHTML;

            $currentPageEl.classList.remove('current');

            if(event.target.classList.contains('next')){
                current_page = parseInt(currentPage) + 1
            }else if(event.target.classList.contains('prev')){
                current_page = parseInt(currentPage)- 1
            } else{
                event.target.classList.add('current');
                current_page = event.target.innerHTML;
            }

            executeQuery(postSearch.value);

        })
    });
}

function filterPostsBySelectedFilter(){
    filters?.forEach(filter => {
        filter.addEventListener('click', debounce(function(event) {
            current_page = 1;
            executeQuery(postSearch.value)
        }, 500));
    });
    postFilters?.forEach(filter => {
        filter.addEventListener('click', debounce(function(event) {
            current_page = 1;
            executeQuery(postSearch.value)
        }, 500));
    });
    postSelect?.addEventListener('input', () => {
        current_page = 1;
        executeQuery(postSearch.value);
    });
}

let filterPostsBySearch = debounce(function(event) {
    event.preventDefault();
    current_page = 1;
    const searchQuery = event.target.value;
    executeQuery(searchQuery)
}, 500)

function buildQuery(searchQuery) {
    let params = new URLSearchParams();
    let filtersValues = [...document.querySelectorAll('input[name="filters[]"]:checked')].map(e => e.value);
    let postFiltersValues = [...document.querySelectorAll('input[name="postFilters[]"]:checked')].map(e => e.value);

    params.append('current_page', current_page);
    params.append('max_pages', max_pages);
    params.append('postsPerPage', per_page);
    filtersValues.forEach((filter) => {
        params.append('filters[]', filter);
    })
    postFiltersValues.forEach((filter) => {
        params.append('postFilters[]', filter);
    })
    postType.split(',').forEach((type) => {
        params.append('postType[]', type);
    });
    params.append('action', 'filter_posts');
    params.append('q', searchQuery);

    if(searchQuery !== ''){
        params.append('base_url', currentUrl + `?q=${searchQuery}&`);
    } else {
        params.append('base_url', currentUrl);
    }

    return params
}

function executeQuery(searchQuery) {
    const params = buildQuery(searchQuery);
    axios.post('/wp-admin/admin-ajax.php', params).then(resp => {
        postsWrapper.innerHTML = resp.data.data;
        ajaxPaginate();
    });
}

function debounce(func, timeout = 300){
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
}

ajaxFilterPosts();