import axios from "axios";

const localSpecialistsWrapper = document.getElementById('local-specialists-wrapper');
const nationalSpecialistsWrapper = document.getElementById('national-specialists-wrapper');
let current_page = localSpecialistsWrapper?.dataset.page;
let max_pages = localSpecialistsWrapper?.dataset.max;
let per_page = localSpecialistsWrapper?.dataset.perPage;
let profile_location = 'local';

const currentUrl = new URL(window.location.href);
const specialistSearch = document.getElementById('local-specialist-search');

const ajaxLocalSpecialistResults = () => {
    document.addEventListener('DOMContentLoaded', function() {
        if(localSpecialistsWrapper) {
            ajaxPaginate();
        }
        if(specialistSearch){
            specialistSearch.addEventListener('input', filterLocalSpecialistsBySearch);
        }
    })
}

function ajaxPaginate(){
    const pageNumbers = document.querySelectorAll('.page-numbers');
    pageNumbers.forEach(pageNumber => {
        pageNumber.removeEventListener('click', paginationEventListener);
        pageNumber.addEventListener('click', paginationEventListener);
    });
}

function paginationEventListener(event) {
    event.preventDefault();
    profile_location = event.target.parentNode.dataset.profileLocation;
    const $currentPageEl = Array.from(document.querySelectorAll(`[data-profile-location="${profile_location}"] > .page-numbers`)).find(item => {
        return item.classList.contains('current');
    });
    const currentPage = $currentPageEl.innerHTML;

    $currentPageEl.classList.remove('current');

    if(event.target.classList.contains('next')){
        current_page = parseInt(currentPage) + 1
    }else if(event.target.classList.contains('prev')){
        current_page = parseInt(currentPage)- 1
    } else{
        event.target.classList.add('current');
        current_page = event.target.innerHTML;
    }

    const params = buildQuery(specialistSearch.value);
    profile_location = event.target.parentNode.dataset.profileLocation;
    executeQuery(params);
}


let filterLocalSpecialistsBySearch = debounce(function(event) {
    event.preventDefault();
    const searchQuery = event.target.value;
    current_page = 1;
    const params = buildQuery(searchQuery);
    executeQuery(params)
}, 500)

function buildQuery(searchQuery) {
    let params = new URLSearchParams();
    params.append('q', searchQuery);
    if(searchQuery !== ''){
        params.append('base_url', currentUrl + `?q=${searchQuery}&`);
    } else {
        params.append('base_url', currentUrl);
    }

    return params
}

function executeQuery(params) {
    params.append('current_page', current_page);
    params.append('location_category', profile_location);
    if (profile_location === 'local') {
        params.append('max_pages', localSpecialistsWrapper?.dataset.max);
        params.append('posts_per_page', localSpecialistsWrapper?.dataset.perPage);
    } else if (profile_location === 'national') {
        params.append('max_pages', nationalSpecialistsWrapper?.dataset.max);
        params.append('posts_per_page', nationalSpecialistsWrapper?.dataset.perPage);
    }
    params.append('action', 'local_specialist_filtering');

    axios.post('/wp-admin/admin-ajax.php', params).then(resp => {
        if (profile_location === 'local') {
            localSpecialistsWrapper.innerHTML = resp.data.data;
        } else if (profile_location === 'national') {
            nationalSpecialistsWrapper.innerHTML = resp.data.data;
        }
        ajaxPaginate();
    });
}

function debounce(func, timeout = 300){
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
}

ajaxLocalSpecialistResults();